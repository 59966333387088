import { FunctionalComponent, h } from "preact";
import { noop } from "lodash";

interface Props {
  toggle: boolean;
  onToggle?: () => void;
}

export const InputToggleButton: FunctionalComponent<Props> = ({
  toggle = false,
  onToggle = noop,
}: Props) => (
  <button type="button" className="btn btn-link px-1" onClick={onToggle}>
    <i class="icon icon-md">{toggle ? "arrow_drop_up" : "arrow_drop_down"}</i>
  </button>
);
