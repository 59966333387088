import { FunctionalComponent, h } from "preact";
import { IStyle } from "common/types/global";

export type ComponentSize = "xl" | "lg" | "sm" | "md";

interface Props extends IStyle {
  iconName?: string;
  size?: ComponentSize;
}

export const Icon: FunctionalComponent<Props> = ({
  iconName,
  size = "sm",
  classNames = "",
}: Props) => (
  <i
    className={`icon text-secondary icon-${size} ${classNames}`}
    style={{ zIndex: 4 }}
  >
    {iconName}
  </i>
);
