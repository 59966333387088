import {
  h,
  SgWidget,
  initializeWidgetConfiguration,
  WidgetConfigurationProvider,
} from "@sgwt-widget/core";
import { emit, props } from "skatejs";
import WidgetTracker from "common/component/WidgetTracker/WidgetTracker";
import { initFetching } from "Sgb4/common/api/api.common";
import {
  isStringEmpty,
  convertToBoolean,
} from "Sgb4/common/strings/stringUtils";
import { ErrorProps } from "Sgb4/DeprecatedTagPicker/common/typings";
import { ContactPicker } from "./ContactPicker";
import { skateJSErrorProps } from "Sgb4/newTagPicker/common/skateJs.typings";
import { ReadContact } from "ic-sgb4-simple-contacts-picker/fetchContacts";
import { mapToContactOutputDto } from "Sgb4/newTagPicker/mapperOutputDto/mapToContactOutputDto";
import { DisplayOption, CreateLinkMode } from "common/types/contact";
import { isStringEmptyOrNull } from "../ic-contact-editor/component/validator";
import { SearchCriteriaCdb } from "../Sgb4/common/types/contact.typings";
import WithCss from "Sgb4/common/Components/WithCss";
import { isEmpty } from "lodash";
import { deprecationMessage } from "../common/depricationMessage";

interface Props extends ErrorProps {
  maxResultCount: number;
  placeholder: string;
  selectedId: string;
  readonlyId: string;
  userEmail: string;
  soaUrl?: string;
  ssoV2: boolean;
  isIcUser?: string;
  internalOnly?: string;
  forceSgb4?: string;
  displayOption: DisplayOption;
  onlyActive?: string;
  createLinkMode: CreateLinkMode;
  size?: string;
}

export class SgWidgetSimpleContactPicker extends SgWidget<Props> {
  public static is = "ic-sgb4-simple-contact-picker";
  public static props = {
    ...skateJSErrorProps,
    maxResultCount: props.number,
    placeholder: props.string,
    selectedId: props.string,
    readonlyId: props.string,
    userEmail: props.string,
    soaUrl: props.string,
    ssoV2: props.boolean,
    isIcUser: props.string,
    internalOnly: props.string,
    displayOption: props.string,
    onlyActive: props.string,
    forceSgb4: props.string,
    createLinkMode: props.string,
    size: props.string,
  };

  public constructor() {
    super();
    deprecationMessage(SgWidgetSimpleContactPicker.is);
  }

  public render({
    soaUrl,
    userEmail,
    maxResultCount,
    selectedId,
    readonlyId,
    ssoV2,
    placeholder,
    inError,
    errorMessage,
    isIcUser,
    internalOnly,
    displayOption = "none",
    onlyActive,
    forceSgb4,
    createLinkMode,
    size,
  }: Props) {
    const defaultPlaceholder = isStringEmpty(placeholder)
      ? "Search contact"
      : placeholder;
    const formattedDisplayOption = isStringEmptyOrNull(displayOption)
      ? "none"
      : displayOption;
    const searchCriteria: SearchCriteriaCdb = {
      isIcUser: convertToBoolean(isIcUser),
      isInternal: convertToBoolean(internalOnly),
      onlyActive: convertToBoolean(onlyActive),
    };

    return (
      <WidgetConfigurationProvider
        widgetConfiguration={this.widgetConfiguration}
      >
        <WithCss
          forceSgb4={convertToBoolean(isEmpty(forceSgb4) ? "true" : forceSgb4)}
        >
          <ContactPicker
            serviceParams={initFetching(
              this.widgetConfiguration,
              soaUrl,
              undefined,
              {},
              userEmail,
              ssoV2
            )}
            size={size}
            inError={inError}
            errorMessage={errorMessage}
            placeholder={defaultPlaceholder}
            maxResultCount={maxResultCount}
            selectedId={selectedId}
            readonlyId={readonlyId}
            searchCriteria={searchCriteria}
            hostName={SgWidgetSimpleContactPicker.is}
            onReady={this.handleOnReady}
            onChange={this.handleOnContactSelectionChange}
            displayOption={formattedDisplayOption}
            createLinkMode={createLinkMode}
            onCreateLinkClicked={this.handleOnCreateLinkClicked}
          />
          <WidgetTracker
            widgetName={SgWidgetSimpleContactPicker.is}
            env={this.widgetConfiguration.environment}
          />
        </WithCss>
      </WidgetConfigurationProvider>
    );
  }

  private handleOnCreateLinkClicked = () =>
    emit(this, `${SgWidgetSimpleContactPicker.is}--create-link-clicked`, {});

  private handleOnContactSelectionChange = (output?: ReadContact) => {
    emit(
      this,
      `${SgWidgetSimpleContactPicker.is}--changed`,
      mapToContactOutputDto(output ? [output] : [])
    );
  };

  private handleOnReady = () => {
    emit(this, `${SgWidgetSimpleContactPicker.is}--ready`, { detail: {} });
  };
}

initializeWidgetConfiguration(SgWidgetSimpleContactPicker);
customElements.define(
  SgWidgetSimpleContactPicker.is,
  SgWidgetSimpleContactPicker
);
