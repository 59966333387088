import { h } from "preact";
import { highlight } from "Sgb4/common/strings/Highlight";
import { ReadContact } from "ic-sgb4-simple-contacts-picker/fetchContacts";
import { get, isEmpty } from "lodash";
import { DisplayOption } from "common/types/contact";
import { BulletValue } from "../../common/BulletValue/BulletValue";

export const renderLineItem = (
  { fullName, employeeOf, emails, jobTitle }: ReadContact,
  searchTerm: string,
  displayOption: DisplayOption = "none"
): JSX.Element => {
  const accountName = get(employeeOf, "name");
  const accountLevel = get(employeeOf, "bdrLegal.level");
  const email = emails && !isEmpty(emails) ? emails[0].value : undefined;
  return (
    <span>
      <span>{highlight(searchTerm, fullName)}</span>
      {accountName && (
        <BulletValue>
          {accountName} {accountLevel ? `(${accountLevel})` : ""}
        </BulletValue>
      )}
      {displayOption === "email" && email && <BulletValue>{email}</BulletValue>}
      {displayOption === "jobtitle" && jobTitle && (
        <BulletValue>{jobTitle}</BulletValue>
      )}
    </span>
  );
};
