import { h, FunctionalComponent } from "preact";
import { Sgb4Tag } from "Sgb4/Tag/Sgb4Tag";
import { map } from "lodash";

export interface Props {
  tags: any[];
  onUnselect: (itemId: string) => void;
  children?: preact.VNode<any>;
}

export const Sgb4TagInput: FunctionalComponent<Props> = ({
  children,
  tags,
  onUnselect,
}: Props) => (
  <div
    class="input-group input-group-merged"
    style="width: 85%;margin-left: 1.7rem!important;"
  >
    <p class="mb-0">
      {map(tags, tag => (
        <Sgb4Tag
          id={tag.id}
          name={tag.name}
          hint={tag.name}
          readonly={tag.readonly}
          onClose={onUnselect}
        />
      ))}
    </p>
    {children}
  </div>
);
