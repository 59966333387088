import { FunctionalComponent, h } from "preact";

interface Props {
  children?: any;
}

export const InputButtons: FunctionalComponent<Props> = ({
  children,
}: Props) => (
  <div className="input-group-append input-group-merged">
    <div className="btn-group">{children}</div>
  </div>
);
