import { FunctionalComponent, h } from "preact";
import { noop } from "lodash";
import {
  getErrorClass,
  InvalidFeedback,
} from "Sgb4/common/Components/InvalidFeedback";
import { IStyle } from "common/types/global";
import { ErrorProps } from "Sgb4/newTagPicker/common/typings";
import { fromStyleLocals } from "common/css";
import * as stylesheet from "./Input.less";
import { Testable } from "@dcc-cli/ic-shared-components";
const css = fromStyleLocals(stylesheet.locals);

export interface Props extends IStyle, ErrorProps, Testable {
  value?: string;
  placeholder?: string;
  readonly?: boolean;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
}

export const Input: FunctionalComponent<Props> = ({
  inError,
  errorType,
  errorMessage,
  value = "",
  placeholder = "",
  onFocus = noop,
  onBlur = noop,
  onInput = noop,
  onChange = noop,
  onKeyUp = noop,
  classNames = "form-control bg-secondary-alt",
  readonly = false,
  e2eId = "",
}: Props) => (
  <div class={`flex-grow-1 ${css("input-search")}`}>
    <style>{stylesheet.toString()}</style>
    <input
      style={{ outline: "none" }}
      type="text"
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      disabled={readonly}
      onChange={(event: any) => onChange(event.target.value)}
      onInput={(event: any) => onInput(event.target.value)}
      className={`${classNames} ${getErrorClass(inError, errorType)}`}
      data-e2e={e2eId}
    />
    {inError && errorMessage && (
      <InvalidFeedback errorMessage={errorMessage} errorType={errorType} />
    )}
  </div>
);
