import { Component, h } from "preact";
import { noop } from "lodash";
import { IStyle } from "common/types/global";

export interface Props extends IStyle {
  id?: string;
  name: string;
  hint?: string;
  readonly?: boolean;
  canClose?: boolean;
  onClose?: (item: string) => void;
  onTagNameClick?: () => void;
}

export class Sgb4Tag extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render({
    id = "",
    name = "",
    hint = "",
    classNames = "",
    onTagNameClick,
    canClose = true,
    readonly = false,
    onClose = noop,
  }: Props) {
    const className = readonly
      ? "badge-light badge-prepend-square"
      : "sgbs-badge-default";

    return (
      <span
        className={`mr-1 badge ${className}`}
        style={onTagNameClick ? { cursor: "pointer" } : {}}
        onClick={onTagNameClick ? onTagNameClick : noop}
        title={hint}
      >
        {canClose && !readonly && (
          <i
            className="icon"
            style="cursor: pointer;"
            onClick={() => onClose(id || name)}
          >
            close
          </i>
        )}
        {name}
      </span>
    );
  }
}
