import { isEmpty, size } from "lodash";
import { EntityType } from "Sgb4/newTagPicker/common/typings";

export const generateSearchMessage = <TItem>(
  hasError: boolean,
  items: TItem[],
  entityType: EntityType,
  searchTerm: string,
  limitBeforeSearch: number,
  hasSearched: boolean = false
) => {
  const displayEnterCharacterMessage =
    entityType !== EntityType.Underlying &&
    entityType !== EntityType.Country &&
    entityType !== EntityType.Other &&
    entityType !== EntityType.Contact;
  return hasError
    ? "Search request failed (server error)."
    : isEmpty(items) && size(searchTerm) >= limitBeforeSearch && hasSearched
    ? `Your search did not match any ${getMessageByEntity(entityType)} name`
    : displayEnterCharacterMessage && searchTerm.length < limitBeforeSearch
    ? `Type more than ${limitBeforeSearch - 1} characters to begin the search `
    : "";
};

const getMessageByEntity = (entityType: EntityType): string => {
  switch (entityType) {
    case EntityType.Account:
      return "account";
    case EntityType.Contact:
      return "contact";
    case EntityType.Underlying:
      return "underlying";
    default:
      return "item";
  }
};
