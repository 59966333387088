import { FunctionalComponent, h } from "preact";
import { Icon } from "Sgb4/common/Components/Icon";
import { IStyle, ComponentSize } from "common/types/global";

interface Props extends IStyle {
  iconName?: string;
  size?: ComponentSize;
}

export const InputIcon: FunctionalComponent<Props> = ({
  iconName,
  classNames,
}: Props) =>
  iconName ? (
    <div className={`input-group-prepend ${classNames}`}>
      <Icon {...{ iconName, size: "" as any }} />
    </div>
  ) : null;
