import { EntityType } from "./typings";

export const getIcon = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Account:
      return "business";
    case EntityType.Contact:
      return "person";
    case EntityType.Underlying:
      return "search";
    case EntityType.Country:
      return "public";
    default:
      return "search";
  }
};
