import { DisplayOption, CreateLinkMode } from "../../../common/types/contact";
import { Testable } from "@dcc-cli/ic-shared-components";

export interface ErrorProps {
  inError?: boolean;
  errorMessage?: string;
  errorType?: "danger" | "warning";
}

export enum EntityType {
  Account,
  Contact,
  Underlying,
  Country,
  Other,
}

export interface IdName<T extends number | string> {
  id: T;
  name: string;
}

export interface TagPickerDispatchProps<TItem> {
  onTermsChange: (terms: string, selectedItems?: TItem[]) => void;
}

export interface MultiTagPickerDispatchProps<TItem>
  extends TagPickerDispatchProps<TItem> {
  onSelect: (items: TItem[], searchTerm?: string) => void;
}

export interface SingleTagPickerDispatchProps<TItem>
  extends TagPickerDispatchProps<TItem> {
  onSelect: (item: TItem, searchTerm?: string) => void;
  onDropdownShown?: () => void;
  onCreateLinkClicked?: () => void;
  onKeyUp?: () => void;
}

export interface TagPickerStateProps<TItem> {
  items: TItem[];
  hostName: string;
  placeholder: string;
  maxResultCount?: number;
  displayOption: DisplayOption;
  renderLineItem: (
    item: TItem,
    searchTerm: string,
    displayOption: DisplayOption
  ) => JSX.Element;
  entityType: EntityType;
  isLoading: boolean;
  hasError: boolean;
  hasSearched?: boolean;
  filterOnClientSide?: boolean;
  createLinkMode?: CreateLinkMode;
  size?: string;
}

export interface MultiTagPickerStateProps<TItem>
  extends TagPickerStateProps<TItem> {
  selectedItems: TItem[];
}

export interface SingleTagPickerStateProps<TItem>
  extends TagPickerStateProps<TItem> {
  selectedItem?: TItem;
}

export type MultiTagPickerProps<TItem> = MultiTagPickerStateProps<TItem> &
  MultiTagPickerDispatchProps<TItem> &
  ErrorProps;

export type SingleTagPickerProps<TItem> = SingleTagPickerStateProps<TItem> &
  SingleTagPickerDispatchProps<TItem> &
  ErrorProps &
  Testable;

export interface TagPickerState<TItem> {
  isDropDownShown?: boolean;
  items: TItem[];
  placeholder: string;
  searchTerm: string;
  highlightIndex?: number;
}

export interface TagPickerTab {
  id: TagPickerTabType;
  name: string;
  selected: boolean;
  disabled: boolean;
}

export enum TagPickerTabType {
  ALL = "ALL",
  SELECTED = "SELECTED",
}

export interface MultiTagPickerState<TItem> extends TagPickerState<TItem> {
  tabs: TagPickerTab[];
  selectedItems: TItem[];
}

export interface SingleTagPickerState<TItem> extends TagPickerState<TItem> {
  selectedItem?: TItem;
}
