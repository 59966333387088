import { Component, h } from "preact";
import {
  ServiceParameters,
  limitBeforeSearch,
} from "Sgb4/common/api/api.common";
import {
  isEmpty,
  first,
  debounce,
  isEqual,
  noop,
  get,
  size as lSize,
} from "lodash";
import { ErrorProps } from "Sgb4/DeprecatedTagPicker/common/typings";
import { EntityType } from "Sgb4/newTagPicker/common/typings";
import { SingleSelectTagPicker } from "Sgb4/newTagPicker/SingleSelect/SingleSelectTagPicker";
import { renderLineItem } from "Sgb4/newTagPicker/renderers/contactRenderer";
import {
  fetchContacts,
  fetchContactsByTerm,
  ReadContact,
} from "ic-sgb4-simple-contacts-picker/fetchContacts";
import { isStringEmpty, isStringEqual } from "Sgb4/common/strings/stringUtils";
import { DisplayOption, CreateLinkMode } from "common/types/contact";
import { SearchCriteriaCdb } from "../Sgb4/common/types/contact.typings";
import { getCreateContactLink } from "ic-sgb4-simple-contacts-picker/contactPickerLinks";

export interface ContactPickerStateProps extends ErrorProps {
  serviceParams: ServiceParameters;
  placeholder: string;
  maxResultCount: number;
  selectedId: string;
  readonlyId: string;
  hostName: string;
  size?: string;
  searchCriteria: SearchCriteriaCdb;
  displayOption: DisplayOption;
  createLinkMode: CreateLinkMode;
}

export interface ContactPickerDispatchProps {
  onChange: (contact: ReadContact | undefined) => void;
  onReady?: () => void;
  onCreateLinkClicked?: () => void;
}

export type ContactPickerProps = ContactPickerStateProps &
  ContactPickerDispatchProps;

export interface ContactPickerState {
  selectedContact: ReadContact | undefined;
  contacts: ReadContact[];
  searchTerm: string;
  isLoading: boolean;
  hasError: boolean;
  hasSearched: boolean;
}

export class ContactPicker extends Component<
  ContactPickerProps,
  ContactPickerState
> {
  private onSearch = debounce(
    (searchTerm: string, selectedContacts: ReadContact[]) => {
      const { serviceParams, maxResultCount, searchCriteria } = this.props;
      this.setState({ searchTerm, contacts: [] }, () =>
        fetchContactsByTerm(
          searchTerm,
          { serviceParams, maxResultCount },
          searchCriteria,
          selectedContacts
        )
          .then(contacts =>
            this.setState({
              contacts,
              isLoading: false,
              hasError: false,
              hasSearched: true,
            })
          )
          .catch(() =>
            this.setState({
              isLoading: false,
              hasError: true,
              hasSearched: true,
            })
          )
      );
    },
    500
  );

  constructor(props: ContactPickerProps) {
    super(props);
    const { selectedId, readonlyId } = this.props;
    this.state = {
      contacts: [],
      selectedContact: undefined,
      searchTerm: "",
      isLoading: false,
      hasError: false,
      hasSearched: false,
    };
    if (!!selectedId && !!readonlyId) {
      throw new Error(
        `Both selectedId: ${selectedId} and readonlyId: ${readonlyId} exist`
      );
    }
  }

  public getChildContext() {
    return { hostName: this.props.hostName };
  }

  public componentDidMount() {
    const { onReady = noop } = this.props;
    onReady();
    this.doFetchContacts();
  }

  public componentDidUpdate(previousProps: Readonly<ContactPickerProps>) {
    const { selectedId, readonlyId } = this.props;
    if (
      !isEqual(previousProps.selectedId, selectedId) ||
      !isEqual(previousProps.readonlyId, readonlyId)
    ) {
      this.doFetchContacts();
    }
  }

  public render(
    {
      hostName,
      placeholder,
      maxResultCount,
      inError,
      errorMessage,
      onChange,
      displayOption = "none",
      createLinkMode,
      size,
    }: ContactPickerProps,
    {
      selectedContact,
      contacts,
      isLoading,
      hasError,
      hasSearched,
    }: ContactPickerState
  ) {
    return (
      <SingleSelectTagPicker<ReadContact>
        selectedItem={selectedContact as ReadContact}
        items={contacts}
        hostName={hostName}
        placeholder={placeholder}
        maxResultCount={maxResultCount}
        size={size}
        entityType={EntityType.Contact}
        renderLineItem={renderLineItem}
        inError={inError}
        errorMessage={errorMessage}
        onSelect={this.handleOnChange}
        onTermsChange={this.handleOnTermsChange}
        isLoading={isLoading}
        hasError={hasError}
        hasSearched={hasSearched}
        displayOption={displayOption}
        createLinkMode={createLinkMode}
        onCreateLinkClicked={this.handleOnCreateLinkClicked}
      />
    );
  }

  private readonly handleOnCreateLinkClicked = () => {
    if (!this.props.createLinkMode || this.props.createLinkMode === "none") {
      return;
    }
    if (
      this.props.createLinkMode === "emit" &&
      this.props.onCreateLinkClicked
    ) {
      this.props.onCreateLinkClicked();
    }
    if (this.props.createLinkMode === "redirect") {
      window.location.assign(
        getCreateContactLink(this.props.serviceParams.environment)
      );
    }
  };

  private handleOnTermsChange = (
    searchTerm: string,
    selectedContacts: ReadContact[]
  ) => {
    this.setState({
      hasSearched: false,
      isLoading: lSize(searchTerm) >= limitBeforeSearch,
    });
    this.onSearch(searchTerm, selectedContacts);
  };

  private handleOnChange = (contact: ReadContact | undefined) => {
    const shouldUpdate = !isStringEqual(
      get(contact, "id"),
      get(this.state.selectedContact, "id")
    );
    if (shouldUpdate) {
      this.setState({ selectedContact: contact }, () =>
        this.props.onChange(contact)
      );
    }
  };

  private doFetchContacts() {
    const { selectedId, readonlyId, serviceParams } = this.props;
    const ids = !isStringEmpty(selectedId) ? [selectedId] : [];
    const readonly = !isStringEmpty(readonlyId) ? [readonlyId] : [];

    if (!isEmpty(ids) || !isEmpty(readonly)) {
      fetchContacts(serviceParams, ids, readonly)
        .then(selectedContacts => this.handleOnChange(first(selectedContacts)))
        .catch(() => this.handleOnChange(undefined));
    } else {
      this.handleOnChange(undefined);
    }
  }
}
