import { FunctionalComponent, h } from "preact";
interface Props {
  children?: any;
}
export const ListGroupRow: FunctionalComponent<Props> = ({
  children,
}: Props) => {
  return (
    <div
      className="content-row row no-gutters"
      style="height: 100%;max-height: 300px;overflow: hidden;"
    >
      {children}
    </div>
  );
};
