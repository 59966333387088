import { FunctionalComponent, h } from "preact";

interface Props {
  colSize?: number;
  borderLeft?: boolean;
  borderRight?: boolean;
  children?: any;
}

export const ListGroupColumn: FunctionalComponent<Props> = ({
  colSize = 12,
  borderLeft = false,
  borderRight = false,
  children,
}: Props) => (
  <div
    style="max-height: 300px;overflow-y: auto;"
    className={
      "content-col px-0 " +
      `col-${colSize}` +
      `${borderLeft ? " border-left" : ""}` +
      `${borderRight ? " border-right" : ""}`
    }
  >
    <div className="list-group">{children}</div>
  </div>
);
