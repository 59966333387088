import { FunctionalComponent, h } from "preact";
import { Props, Input } from "Sgb4/Input/Input";
import { Sgb4TagInput } from "Sgb4/Tag/Sgb4TagInput";
import { Testable } from "@dcc-cli/ic-shared-components";
/* tslint:disable */
export interface InputTagProps extends Props, Testable {
  tags: any[];
  readonly?: boolean;
  onUnselect: (itemId: string) => void;
  value: string;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  onInput?: (value: string) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
}

export const InputTagList: FunctionalComponent<InputTagProps> = ({
  tags,
  onUnselect,
  placeholder,
  inError,
  value,
  onInput: onChange,
  onFocus,
  onBlur,
  onKeyUp,
  readonly,
  e2eId = "",
}: InputTagProps) => (
  <Sgb4TagInput {...{ tags, onUnselect, inError }}>
    <Input
      {...{
        placeholder,
        inError,
        onInput: onChange,
        onFocus,
        onBlur,
        onKeyUp,
        value,
        readonly,
        classNames:
          "border-0 flex-grow-1 bg-transparent bg-secondary-alt w-100",
        e2eId,
      }}
    />
  </Sgb4TagInput>
);
