import { Component, h } from "preact";
import { InputClearButton } from "../Input/InputClearbutton";
import { InputIcon } from "../Input/InputIcon";
import { noop, isEqual } from "lodash";
import { InputButtons } from "Sgb4/Input/InputButtons";
import { InputToggleButton } from "Sgb4/Input/InputToggleButton";
import {
  InvalidFeedback,
  getErrorClass,
} from "Sgb4/common/Components/InvalidFeedback";
import { InputTagList } from "./InputTagList";
import { ErrorProps } from "Sgb4/newTagPicker/common/typings";
import { Testable } from "@dcc-cli/ic-shared-components";
import { fromStyleLocals } from "common/css";
import * as stylesheet from "./Combobox.less";
import { isStringEqual } from "Sgb4/common/strings/stringUtils";

const css = fromStyleLocals(stylesheet.locals);

interface Props<TItem> extends ErrorProps, Testable {
  pickerId?: string;
  tags?: TItem[];
  readonly?: boolean;
  displayClearButton?: boolean;
  term?: string;
  placeholder?: string;
  iconName?: string;
  renderLisGroup: () => JSX.Element;
  isDropDownShown?: boolean;
  size?: string;
  onClear?: () => void;
  onToggleDisplayDropDown: (isShown?: boolean, fromFocus?: boolean) => void;
  onValueChange?: (value: string) => void;
  onUnselect?: (itemId: string) => void;
  onClickOutside?: () => void;
  onKeyUp?: (event: KeyboardEvent) => void;
}

interface State {
  term: string;
  isDropDownShown: boolean;
  hasFocus: boolean;
}

export class Combobox<
  TItem extends { id: string; name: string }
> extends Component<Props<TItem>, State> {
  public static defaultProps = {
    isDropDownShown: false,
    onToggleDisplayDropDown: noop,
  };
  constructor(props: Props<TItem>) {
    super(props);
    this.state = {
      term: props.term || "",
      isDropDownShown: props.isDropDownShown || false,
      hasFocus: false,
    };
    this.handleOnLostFocus = this.handleOnLostFocus.bind(this);
    this.handleOnHasFocus = this.handleOnHasFocus.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleToggleDropDown = this.handleToggleDropDown.bind(this);
    this.handleUnSelectTag = this.handleUnSelectTag.bind(this);
  }

  public componentDidMount() {
    document.addEventListener("click", this.handleClose, false);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleClose);
  }

  public componentWillReceiveProps?(nextProps: Props<TItem>) {
    if (!isEqual(nextProps.term, this.props.term)) {
      this.setState({ term: nextProps.term || "" });
    }
    if (!isEqual(nextProps.isDropDownShown, this.props.isDropDownShown)) {
      this.setState(
        { isDropDownShown: nextProps.isDropDownShown || false },
        () =>
          this.props.onToggleDisplayDropDown(nextProps.isDropDownShown || false)
      );
    }
  }

  public render(
    {
      placeholder,
      iconName,
      renderLisGroup,
      inError,
      errorMessage,
      onUnselect = noop,
      displayClearButton = true,
      readonly = false,
      tags = [],
      e2eId = "",
      onKeyUp,
      size,
    }: Props<TItem>,
    state: State
  ) {
    return (
      <div>
        <style>{stylesheet.toString()}</style>
        <div class={this.classNames(inError, size)}>
          <InputIcon iconName={iconName} />
          <InputButtons>
            {displayClearButton && (
              <InputClearButton onClear={this.handleClear} />
            )}
            <InputToggleButton
              toggle={state.isDropDownShown}
              onToggle={
                !readonly
                  ? () => this.handleToggleDropDown(!state.isDropDownShown)
                  : noop
              }
            />
          </InputButtons>
          <InputTagList
            {...{ tags, inError, placeholder, readonly }}
            onInput={this.handleTermChange}
            onUnselect={this.handleUnSelectTag}
            onFocus={this.handleOnHasFocus}
            onBlur={this.handleOnLostFocus}
            onKeyUp={onKeyUp}
            value={state.term}
            e2eId={e2eId}
          />
          {state.isDropDownShown && renderLisGroup()}
        </div>
        {inError && <InvalidFeedback errorMessage={errorMessage} />}
      </div>
    );
  }

  private classNames = (inError: boolean | undefined, size = "md") => {
    const focusStyle = this.state.hasFocus ? css("input-focus") : "";
    return `sgb-input input-group py-0
    form-group form-control form-control-${size} d-flex align-items-center flex-wrap bg-secondary-alt mb-0 ${getErrorClass(
      inError
    )} ${focusStyle} `;
  };

  private handleClose(event: any) {
    if (this.base && this.base.contains(event.target)) {
      return;
    }
    // this.base.getRootNode() not working in ie
    const hostName = this.context.hostName || "";
    if (
      event.target.id &&
      isStringEqual(this.props.pickerId, event.target.id)
    ) {
      return;
    } else if (event.target.nodeName.toUpperCase() === hostName.toUpperCase()) {
      return;
    }
    this.setState({ term: "" }, () => {
      this.handleToggleDropDown(false);
      if (this.props.onClickOutside) {
        this.props.onClickOutside();
      }
    });
  }

  private handleUnSelectTag = (searchedText: string) => {
    if (this.props.onUnselect) {
      this.props.onUnselect(searchedText);
      this.handleToggleDropDown(false);
    }
  };

  private handleToggleDropDown(isDropDownShown: boolean, fromFocus = false) {
    this.setState({ isDropDownShown }, () =>
      this.props.onToggleDisplayDropDown(isDropDownShown, fromFocus)
    );
  }

  private handleTermChange(term: string) {
    const onValueChange = this.props.onValueChange || noop;
    this.setState({ term, isDropDownShown: true }, () => {
      onValueChange(this.state.term);
      this.props.onToggleDisplayDropDown(this.state.isDropDownShown);
    });
  }

  private handleClear() {
    (this.props.onClear || noop)();
  }

  private handleOnLostFocus() {
    this.setState({ hasFocus: false });
  }

  private handleOnHasFocus() {
    this.setState({ hasFocus: true });
    this.handleToggleDropDown(true, true);
  }
}
