import { h, FunctionalComponent } from "preact";
import { ListGroupRow } from "Sgb4/ListGroup/ListGroupRow";
import { ListGroupColumn } from "Sgb4/ListGroup/ListGroupColumn";
import { ListGroupItemLoader } from "Sgb4/ListGroup/ListGroupItemLoader";
import { ListGroupItem } from "Sgb4/ListGroup/ListGroupItem";
import { isStringEmpty } from "Sgb4/common/strings/stringUtils";
import { ListGroupItemMessage } from "Sgb4/ListGroup/ListGroupItemMessage";
import { map, noop } from "lodash";
import { DisplayOption } from "common/types/contact";

interface Props {
  items: any[];
  searchTerm: string;
  isLoading: boolean;
  message: string;
  displayOption: DisplayOption;
  shouldDisplayMessage?: boolean;
  selectable?: boolean;
  onSelection: (item: any, isSelected: boolean) => void;
  renderLineItem: (
    item: any,
    searchTerm: string,
    displayOption: DisplayOption
  ) => JSX.Element;
}

export const ItemsListGroupRow: FunctionalComponent<Props> = ({
  isLoading,
  message,
  items,
  onSelection,
  searchTerm,
  displayOption,
  renderLineItem,
  shouldDisplayMessage = true,
  selectable = true,
}: Props) => {
  return (
    <ListGroupRow>
      <ListGroupColumn>
        <ListGroupItemMessage
          isShown={
            !isLoading && !isStringEmpty(message) && shouldDisplayMessage
          }
          message={message}
        />
        <ListGroupItemLoader isLoading={isLoading} />
        {!isLoading &&
          map(items, item =>
            item.filtered ? null : (
              <ListGroupItem
                key={item.id}
                label={item.name}
                id={item.id}
                selectable={selectable}
                selected={item.selected}
                readonly={item.readonly}
                highlighted={item.isHighlighted || false}
                onSelectionChange={
                  !item.readonly
                    ? (_, isSelected) => onSelection(item, isSelected)
                    : noop
                }
                customRender={() =>
                  renderLineItem(item, searchTerm, displayOption)
                }
              />
            )
          )}
      </ListGroupColumn>
    </ListGroupRow>
  );
};
