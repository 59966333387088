import { Component, h } from "preact";
import { Combobox } from "Sgb4/Combobox/Combobox";
import { limitBeforeSearch } from "Sgb4/common/api/api.common";
import { isEmpty, isEqual, get, noop, map, size as lSize } from "lodash";
import {
  SingleTagPickerProps,
  SingleTagPickerState,
} from "Sgb4/newTagPicker/common/typings";
import { generateSearchMessage } from "../common/message";
import { ListGroup } from "Sgb4/ListGroup/ListGroup";
import { ItemsListGroupRow } from "../ItemsListGroup/ItemsListGroupRow";
import { getIcon } from "../common/icon";
import { isStringEqual } from "Sgb4/common/strings/stringUtils";
import { ListGroupItem } from "Sgb4/ListGroup/ListGroupItem";

export class SingleSelectTagPicker<
  TItem extends { id: string; name: string; isHighlighted?: boolean }
> extends Component<SingleTagPickerProps<TItem>, SingleTagPickerState<TItem>> {
  constructor(props: SingleTagPickerProps<TItem>) {
    super(props);
    this.state = {
      selectedItem: props.selectedItem,
      items: props.items,
      isDropDownShown: false,
      placeholder: props.placeholder,
      searchTerm: "",
      highlightIndex: -1,
    };
  }

  public getChildContext() {
    return { hostName: this.props.hostName };
  }

  public componentWillReceiveProps(nextProps: SingleTagPickerProps<TItem>) {
    const { items, selectedItem } = this.props;
    const isItemEmpty = isEmpty(nextProps.selectedItem);
    if (!isEqual(items, nextProps.items)) {
      this.setState({ items: nextProps.items });
    }
    if (!isEqual(selectedItem, nextProps.selectedItem)) {
      const newSelectedItem = !isItemEmpty
        ? { ...(nextProps.selectedItem as any), selected: true }
        : undefined;
      this.handleSelection(newSelectedItem, !isItemEmpty);
    }
  }

  public render(
    {
      inError,
      errorMessage,
      renderLineItem,
      entityType,
      isLoading,
      hasError,
      hasSearched,
      displayOption,
      e2eId = "",
      createLinkMode,
      onCreateLinkClicked,
      size,
    }: SingleTagPickerProps<TItem>,
    state: SingleTagPickerState<TItem>
  ) {
    const searchMessage = generateSearchMessage(
      hasError,
      state.items,
      entityType,
      state.searchTerm,
      limitBeforeSearch,
      hasSearched
    );
    const isReadonly = get(state.selectedItem, "readonly");
    const isDisplayCreateLink = createLinkMode && createLinkMode !== "none";
    const shouldDisplayCreateLink = !inError && lSize(state.items) > 0;
    return (
      <Combobox
        {...{ inError, errorMessage }}
        term={state.searchTerm}
        readonly={isReadonly}
        iconName={getIcon(entityType)}
        isDropDownShown={state.isDropDownShown}
        onToggleDisplayDropDown={this.handleToggleDisplayDropDown}
        placeholder={!!state.selectedItem ? "" : state.placeholder}
        onValueChange={this.handleOnTermChange}
        onClear={!isReadonly ? this.handleOnSearchClear : noop}
        tags={state.selectedItem ? [state.selectedItem] : []}
        onUnselect={this.handleUnSelect}
        displayClearButton={!isEmpty(state.selectedItem)}
        onClickOutside={this.handleOnClickOutside}
        e2eId={e2eId}
        onKeyUp={this.handleOnKeyUp}
        size={size}
        renderLisGroup={() => (
          <ListGroup size={size}>
            <ItemsListGroupRow
              searchTerm={state.searchTerm}
              items={state.items}
              onSelection={this.handleSelection}
              isLoading={isLoading}
              message={searchMessage}
              renderLineItem={renderLineItem}
              displayOption={displayOption}
            />
            {isDisplayCreateLink && shouldDisplayCreateLink && (
              <ListGroupItem
                id="create-link"
                className="text-center font-weight-medium border-top"
                label={"Create new contact"}
                selectable={false}
                selected={false}
                disabled={false}
                onSelectionChange={onCreateLinkClicked}
              />
            )}
          </ListGroup>
        )}
      />
    );
  }

  private handleOnKeyUp = (event: KeyboardEvent) => {
    const index = this.state.highlightIndex as number;
    const items = this.state.items;
    if (
      isStringEqual(event.key, "Enter") &&
      index > -1 &&
      index < lSize(items)
    ) {
      this.setState({
        selectedItem: items[index],
        searchTerm: "",
        isDropDownShown: false,
        highlightIndex: -1,
      });
    } else if (isStringEqual(event.key, "ArrowDown")) {
      index > -1 && index < lSize(items)
        ? (items[index].isHighlighted = false)
        : noop();
      const highlightIndex = index < lSize(items) ? index + 1 : index;
      items[highlightIndex].isHighlighted = true;
      this.setState({ items, highlightIndex });
    } else if (isStringEqual(event.key, "ArrowUp")) {
      index > -1 ? (items[index].isHighlighted = false) : noop();
      const highlightIndex = index >= -1 ? index - 1 : index;
      highlightIndex === -1
        ? noop()
        : (items[highlightIndex].isHighlighted = true);
      this.setState({ items, highlightIndex });
    }
  };

  private handleOnSearchClear = () => {
    const { onSelect } = this.props;
    const { items, searchTerm } = this.state;
    this.setState(
      {
        items: map(items, item => ({ ...(item as any), selected: false })),
        selectedItem: undefined as any,
      },
      () => onSelect(undefined as any, searchTerm)
    );
  };

  private handleOnTermChange = (searchTerm: string) => {
    const { selectedItem } = this.state;
    const { filterOnClientSide } = this.props;
    this.setState(
      { searchTerm, items: filterOnClientSide ? this.state.items : [] },
      () =>
        this.props.onTermsChange(searchTerm, selectedItem ? [selectedItem] : [])
    );
  };

  private handleOnClickOutside = () => {
    const { filterOnClientSide } = this.props;
    if (!filterOnClientSide) {
      this.setState({ items: [] });
    }
    this.setState({ highlightIndex: -1 });
  };

  private handleUnSelect = (itemId: string) =>
    get(this.state.selectedItem, "id") === itemId
      ? this.handleSelection(this.state.selectedItem, false)
      : noop();

  private handleSelection = (item?: TItem, isSelected = false) => {
    const selectedItem = isSelected ? item : undefined;
    if (
      !isStringEqual(
        get(selectedItem, "id"),
        get(this.state.selectedItem, "id")
      )
    ) {
      this.setState(
        {
          searchTerm: "",
          items: this.props.filterOnClientSide ? this.state.items : [],
          selectedItem: selectedItem as TItem,
          isDropDownShown: !isSelected,
        },
        () => this.props.onSelect(selectedItem as TItem)
      );
    }
  };

  private handleToggleDisplayDropDown = (
    isDropDownShown: boolean,
    fromFocus?: boolean
  ) => {
    this.setState({ isDropDownShown }, () =>
      fromFocus && isDropDownShown && this.props.onDropdownShown
        ? this.props.onDropdownShown()
        : noop()
    );
  };
}
